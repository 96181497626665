import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import { toggleDrawer } from '../../actions/layout.actions';
import { logout } from '../../actions/auth.actions';

import DrawerTop from './DrawerTop';
import DrawerMiddle from './DrawerMiddle';

class Drawer extends Component {
  handleToggle = () => {
    this.props.toggleDrawer();
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { router, drawerOpen } = this.props;
    return (
      <div className={`root-drawer ${ drawerOpen ? `is-open` : `` }`}>
        <DrawerTop
          isOpen={drawerOpen}
          onToggle={this.handleToggle}
        />
        <DrawerMiddle
          router={router}
          onLogout={this.handleLogout}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  router: state.router,
  drawerOpen: state.layout.drawerOpen
});

const mapDispatchToProps = dispatch => ({
  toggleDrawer: () => dispatch(toggleDrawer()),
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Drawer);
