import * as Types from '../types/notification.types';

const intialState = {
  formLoading: false,
  formSuccess: false
};

export default function(state = intialState, action){
  const { type } = action;
  switch (type){
    // Request
    case Types.SEND_NOTIFICATION:
      return { ...state, formLoading: true, formSuccess: false };
    // Success
    case Types.SEND_NOTIFICATION_SUCCESS:
      return { ...state, formLoading: false, formSuccess: true };
    // Failure
    case Types.SEND_NOTIFICATION_FAILURE:
    case Types.RESET_FORM:
      return { ...state, ...intialState }
    default:
      return state;
  }
}
