import * as Types from '../types/user.types';

// Fetch me
export const fetchMe = () => ({
  type: Types.FETCH_ME
});
export const fetchMeSuccess = user => ({
  type: Types.FETCH_ME_SUCCESS,
  user
});
// Fetch users list
export const fetchUsersList = params => ({
  type: Types.FETCH_USERS_LIST,
  params
});
export const fetchUsersListSuccess = users => ({
  type: Types.FETCH_USERS_LIST_SUCCESS,
  users
});


// Checked user
export const checkUser = user => ({
  type: Types.CHECK_USER,
  user
});