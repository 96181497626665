import './AppLoading.scss';
import React from 'react';

const AppLoading = () => {
  return (
    <div className="app-loading" />
  );
}

export default AppLoading;
