import React from 'react';
import { Switch, Route } from 'react-router-dom';
// Utils
import PrivateRoute from './utils/private.route';
// Pages
import Login from './pages/Auth/Login';
import RecoverPassword from './pages/Auth/RecoverPassword';
import UpdatePassword from './pages/Auth/UpdatePassword';
// Admin
import Admin from './pages/Admin';
import Profile from './pages/Profile';

import NotFound from './pages/NotFound';

export const AppRouting = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/recover-password" component={RecoverPassword} />
      <Route path="/update-password" component={UpdatePassword} />

      <PrivateRoute path="/admin" component={Admin} />
      <PrivateRoute path="/profile" component={Profile} />

      <Route path="*" component={NotFound} />
    </Switch>
  )
}
