import React from 'react';
import { Link } from 'react-router-dom';

const DrawerMiddle = ({ router, onLogout }) => {
  const routes = [
    { to: '/admin/users', text: 'Users', icon: 'fa-users' },
    { to: '/admin/templates', text: 'Templates', icon: 'fa-building' },
    { to: '/admin/schedules', text: 'Schedules', icon: 'fa-calendar-alt' }
  ];

  return (
    <div className="root-drawer-middle">
      <ul className="nav nav-drawer flex-column">
        {routes.map((item, index) => (
          <Link
            key={index}
            to={item.to}
            className={`nav-link ${router.location.pathname.indexOf(item.to) !== -1 ? `is-active` : ``}`}
          >
            <span className="nav-link-icon">
              <i className={`fa ${item.icon}`} aria-hidden="true"></i>
            </span>
            <span className="nav-link-text">{item.text}</span>
          </Link>
        ))}
      </ul>
      <hr />
      <ul className="nav nav-drawer flex-column">
        <li className="nav-item">
          <Link
            className={`nav-link ${router.location.pathname.indexOf('/profile') !== -1 ? `is-active` : ``}`}
            to="/profile"
          >
            <span className="nav-link-icon">
              <i className="fa fa-user" aria-hidden="true"></i>
            </span>
            <span className="nav-link-text">Profile</span>
          </Link>
        </li>
        <li className="nav-item">
          <button
            className="nav-link"
            onClick={onLogout}
          >
            <span className="nav-link-icon">
              <i className="fa fa-sign-out-alt" aria-hidden="true"></i>
            </span>
            <span className="nav-link-text">Logout</span>
          </button>
        </li>
      </ul>
    </div>
  )
}

export default DrawerMiddle;
