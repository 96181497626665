import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// Reducers
import layoutReducer from './layout.reducer';
import authReducer from './auth.reducer';
import userReducer from './user.reducer';
import templateReducer from './template.reducer';
import notificationReducer from './notification.reducer';

const rootReducer = history => combineReducers({
  layout: layoutReducer,
  auth: authReducer,
  user: userReducer,
  template: templateReducer,
  notification: notificationReducer,
  router: connectRouter(history)
});

export default rootReducer;
