import axios from './axios.instance';
// Service
import { getToken, isExpired } from './storage.service';
// Utils
import { errorHandler } from '../utils/request.handler';

// Login
// @params { object } credential
// @params { string } credential.email
// @params { string } credential.password
export function $login(credential){
  return axios.post('/auth/login', credential)
    .catch(errorHandler);
}

// Logout
export function $logout(){
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('Logout');
    }, 500);
  })
}

// Check is expired
export function $checkIsTokenExpired(){
  return new Promise((resolve, reject) => {
    if ( getToken() ){
      console.log('Is expired', isExpired());
      if ( isExpired() ){
        const msg = 'Session is expired';
        errorHandler(msg);
        reject(msg);
      } else {
        resolve(true);
      }
    } else {
      resolve(false);
    }
  });
}
