import React from 'react';

import LoginForm from './LoginForm';

const Login = () => {
  return (
    <div className="area area-auth">
      <div className="container-fluid">
        <div className="area-container">
          <h1 className="area-title">Login</h1>
          <LoginForm />
        </div>
      </div>
    </div>
  )
}

export default Login;
