import { takeEvery, put, delay } from 'redux-saga/effects';
import * as Types from '../types/layout.types';
import { removeNotification } from '../actions/layout.actions';

function* _addNotification(action){
  yield delay(4000);
  yield put(removeNotification(action.notification));
}

export function* layoutSaga(){
  yield takeEvery(Types.ADD_NOTIFICATION, _addNotification);
}
