import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import { fetchUsersList } from '../../../actions/user.actions';
// Components
import { DataLoading } from '../../../components/Loadings';
// Layouts
import { UsersNotificationModal } from '../../../layouts/Modals';
//
import UsersFilter from './UsersFilter';
import UsersList from './UsersList';

class Users extends Component {
  state = {
    isModalShown: false
  }

  componentWillMount(){
    this.props.fetchUsersList();
  }

  handleModalShow = evt => {
    this.setState(prevState => ({
      isModalShown: !prevState.isModalShown
    }))
  }

  render() {
    const { isModalShown } = this.state;
    const { checkedUsers, fetching, fetched } = this.props;
    return (
      <div className="area area-templates">
        <div className="container-fluid">
          {/* <h1 className="area-title">Users</h1> */}
          <div className="area-container">
            <div className="form-group clearfix">
              <button
                className="btn btn-primary btn-sm | float-right"
                type="button"
                onClick={this.handleModalShow}
                disabled={checkedUsers.length === 0}
              >Send message</button>
            </div>
            <UsersFilter
              onFetchUsersList={this.props.fetchUsersList}
            />
            <hr />
            {( fetching && !fetched) && <DataLoading />}
            {( !fetching && fetched ) &&
              <UsersList />
            }

            {/* Modal */}
            {isModalShown &&
              <UsersNotificationModal
                show={isModalShown}
                onHide={this.handleModalShow}
              />
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checkedUsers: state.user.checkedUsers,
  fetching: state.user.fetching,
  fetched: state.user.fetched,
});

const mapDispatchToProps = dispatch => ({
  fetchUsersList: params => dispatch(fetchUsersList(params))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
