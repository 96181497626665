import axios from './axios.instance';
// Utils
import { responseHandler, errorHandler } from '../utils/request.handler';

const _SENT = 'Notification sent';

// Send one time notification
export function $sendNotification(data){
  return axios.post('/admin/notifications', data)
    .then(response => responseHandler(response, _SENT))
    .catch(errorHandler);
}
