import * as AuthTypes from '../types/auth.types';
import * as UserTypes from '../types/user.types';

const initialState = {
  user: {},
  token: '',
  isAuthenticated: '',

  formLoading: false,
  formSuccess: false,

  fetching: false,
  fetched: false
};

export default function(state = initialState, action){
  const { type } = action;
  switch (type){
    // Request
    case AuthTypes.LOGIN:
      return { ...state, formLoading: true, formSuccess: false };
    // Success
    case AuthTypes.SET_LOGIN_CREDENTIAL:
      return _setLoginCredential(state, action.authCredential);
    case AuthTypes.LOGOUT_SUCCESS:
      return _logoutSuccess(state);
    case UserTypes.FETCH_ME_SUCCESS:
      return { ...state, user: action.user, isAuthenticated: true };
    // Failure
    case AuthTypes.LOGIN_FAILURE:
      return { ...state, formLoading: false, formSuccess: false };
    default:
      return state;
  }
}

// Login
const _setLoginCredential = (state, { accessToken }) => ({
  ...state, token: accessToken
});

// Logout
const _logoutSuccess = state => ({
  ...state, user: {}, token: '', isAuthenticated: false
});
