import React from 'react';

import validate from '../../utils/control.validate';

const TextareaControl = ({
  value, submitted = false, options, classes = 'form-control form-control-sm ', onChange, onFocus = () => {}
}) => {
  let { id, label, name, rows = 5, placeholder } = options;

  let inputRequired = false;

  if ( options.rules && options.rules.isRequired ) inputRequired = true;

  let valid = {
    isValid: true,
    validateMessage: ''
  };
  if ( submitted && inputRequired ){
    valid = validate(value, options.rules);
  }

  classes += !valid.isValid ? ' is-invalid' : '';

  return (
    <div className="form-group">
      {
        label &&
        <label htmlFor={id}>
          {label}
          {inputRequired && <span>&nbsp;*</span>}
        </label>
      }
      <textarea
        id={id}
        className={classes}
        name={name}
        value={value}
        placeholder={placeholder}
        rows={rows}
        onChange={onChange}
        onFocus={onFocus}
      ></textarea>
      {
        valid.validateMessage &&
        <div className="invalid-feedback">{valid.validateMessage}</div>
      }
    </div>
  )
}

export default TextareaControl;
