import { takeLatest, call, put } from 'redux-saga/effects';
// Service
import {
  $fetchTemplatesList,
  $createTemplate,
  $updateTemplate,
  $deleteTemplate,
  $fetchTemplatesVariables
} from '../services/template.service';
// Types
import * as Types from '../types/template.types';
// Actions
import {
  fetchTemplatesListSuccess,
  createTemplateSuccess, createTemplateFailure,
  updateTemplateSuccess, updateTemplateFailure,
  deleteTemplateSuccess, deleteTemplateFailure,
  fetchTemplatesVariablesSuccess,
  resetForm
} from '../actions/template.actions';

// Fetch templates variables
function* _fetchTemplatesList(){
  const response = yield call($fetchTemplatesList);
  yield put(fetchTemplatesListSuccess(response.data));
}

// Create template
function* _createTemplate(action){
  try {
    const response = yield call($createTemplate, action.template);
    yield put(createTemplateSuccess(response.data));
    yield put(resetForm());
  } catch(err){
    yield put(createTemplateFailure());
    console.error(err);
  }
}
// Update template
function* _updateTemplate(action){
  try {
    const response = yield call($updateTemplate, action.template);
    yield put(updateTemplateSuccess(response.data));
    yield put(resetForm());
  } catch(err){
    yield put(updateTemplateFailure());
    console.error(err);
  }
}
// Delete template
function* _deleteTemplate(action){
  try {
    if ( window.confirm('Are you sure you want to delete template?') ){
      yield call($deleteTemplate, action.templateId);
      yield put(deleteTemplateSuccess(action.templateId));
    }
  } catch(err){
    yield put(deleteTemplateFailure());
    console.error(err);
  }
}

// Fetch templates variables
function* _fetchTemplatesVariables(){
  const response = yield call($fetchTemplatesVariables);
  yield put(fetchTemplatesVariablesSuccess(response.data));
}

export function* templateSaga(){
  yield takeLatest(Types.FETCH_TEMPLATES_LIST, _fetchTemplatesList);
  yield takeLatest(Types.CREATE_TEMPLATE, _createTemplate);
  yield takeLatest(Types.UPDATE_TEMPLATE, _updateTemplate);
  yield takeLatest(Types.DELETE_TEMPLATE, _deleteTemplate);
  yield takeLatest(Types.FETCH_TEMPLATES_VARIABLES, _fetchTemplatesVariables);
}
