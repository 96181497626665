export default function(value, rules, match, msg){
  let isValid = true;
  let validateMessage = '';
  for ( let rule in rules ){
    switch (rule){
      case 'isRequired':
        isValid = isValid && _requiredValidator(value);
        validateMessage = !isValid ? 'This field is required' : '';
        break;
      case 'isEmail':
        isValid = isValid && _emailValidator(value);
        validateMessage = !isValid ? 'E-mail is not valid' : '';
        break;
      case 'isMatch':
        isValid = isValid && _match(value, match);
        validateMessage = !isValid ? msg : '';
        break;
      default:
        isValid = true;
    }
    if ( !isValid ) break;
  }
  return {
    isValid,
    validateMessage
  };
}

/*
  Required
  @param value
  @return
*/
const _requiredValidator = value => {
  return value.toString().trim() !== '';
}

/*
  Email
  @param value
  @return
*/
const _emailValidator = value => {
  const regexp = /^[a-z][a-zA-Z0-9_.]*(\.[a-zA-Z][a-zA-Z0-9_.]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/;
  const validationRegexp = (regexp instanceof RegExp ? regexp : (new RegExp(regexp)));
  return validationRegexp.test(value);
}


/*
  Match
  @param value,
  @param secondValue
  @return
*/
const _match = (value, secondValue) => {
  return value.trim() === secondValue.trim();
}