import * as Types from '../types/notification.types';

// Send one time notification
export const sendNotification = data => ({
  type: Types.SEND_NOTIFICATION,
  data
});
export const sendNotificationSuccess = () => ({
  type: Types.SEND_NOTIFICATION_SUCCESS
});
export const sendNotificationFailure = () => ({
  type: Types.SEND_NOTIFICATION_FAILURE
})

// Reset form
export const resetForm = () => ({
  types: Types.RESET_FORM
});