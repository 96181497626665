import * as Types from '../types/user.types';

const initialState = {
  users: [],
  checkedUsers: [],

  fetching: false,
  fecthed: false
};

export default function(state = initialState, action){
  const { type } = action;
  switch (type){
    // Request
    case Types.FETCH_USERS_LIST:
      return _fetchUsersList(state);
    // Success
    case Types.FETCH_USERS_LIST_SUCCESS:
      return _fetchUsersListSuccess(state, action.users);
    // Failure
    // 
    case Types.CHECK_USER:
      return _checkUser(state, action.user);
    default:
      return state;
  }
}

// Fetch users list
const _fetchUsersList = state => ({
  ...state, users: [], fetching: true, fetched: false
});
const _fetchUsersListSuccess = (state, users) => ({
  ...state, users, fetching: false, fetched: true
});

// Check user
const _checkUser = (state, user) => {
  const u = state.checkedUsers.find(u => u.id === user.id);
  const checkedUsers = !u
    ? [...state.checkedUsers, user]
    : state.checkedUsers.filter(u => u.id !== user.id);
  return {
    ...state,
    checkedUsers
  }
}
