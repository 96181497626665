import { all } from 'redux-saga/effects';
// Sagas
import { layoutSaga } from './layout.saga';
import { authSaga } from './auth.saga';
import { userSaga } from './user.saga';
import { templateSaga } from './template.saga';
import { notificationSaga } from './notification.saga';

export default function* rootSaga(){
  yield all([
    layoutSaga(),
    authSaga(),
    userSaga(),
    templateSaga(),
    notificationSaga()
  ]);
}
