import createType from './create-type';

const type = 'template';

export const FETCH_TEMPLATES_LIST = createType(type, 'FETCH_TEMPLATES_LIST');
export const FETCH_TEMPLATES_LIST_SUCCESS = createType(type, 'FETCH_TEMPLATES_LIST_SUCCESS');

export const CREATE_TEMPLATE = createType(type, 'CREATE_TEMPLATE');
export const CREATE_TEMPLATE_SUCCESS = createType(type, 'CREATE_TEMPLATE_SUCCESS');
export const CREATE_TEMPLATE_FAILURE = createType(type, 'CREATE_TEMPLATE_FAILURE');

export const UPDATE_TEMPLATE = createType(type, 'UPDATE_TEMPLATE');
export const UPDATE_TEMPLATE_SUCCESS = createType(type, 'UPDATE_TEMPLATE_SUCCESS');
export const UPDATE_TEMPLATE_FAILURE = createType(type, 'UPDATE_TEMPLATE_FAILURE');

export const DELETE_TEMPLATE = createType(type, 'DELETE_TEMPLATE');
export const DELETE_TEMPLATE_SUCCESS = createType(type, 'DELETE_TEMPLATE_SUCCESS');
export const DELETE_TEMPLATE_FAILURE = createType(type, 'DELETE_TEMPLATE_FAILURE');

export const FETCH_TEMPLATES_VARIABLES = createType(type, 'FETCH_TEMPLATES_VARIABLES');
export const FETCH_TEMPLATES_VARIABLES_SUCCESS = createType(type, 'FETCH_TEMPLATES_VARIABLES_SUCCESS');

// 
export const SET_SELECTED_TEMPLATE = createType(type, 'SET_SELECTED_TEMPLATE');
export const CLEAR_SELECTED_TEMPLATE = createType(type, 'CLEAR_SELECTED_TEMPLATE');

export const RESET_FORM = createType(type, 'RESET_FORM');
