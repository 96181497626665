import createType from './create-type';

const type = 'auth';

export const LOGIN = createType(type, 'LOGIN');
export const SET_LOGIN_CREDENTIAL = createType(type, 'SET_LOGIN_CREDENTIAL');;
export const LOGIN_FAILURE = createType(type, 'LOGIN_FAILURE');

export const LOGOUT = createType(type, 'LOGOUT');;
export const LOGOUT_SUCCESS = createType(type, 'LOGOUT_SUCCESS');;

export const CHECK_IS_TOKEN_EXPIRED = createType(type, 'CHECK_IS_TOKEN_EXPIRED');;
export const TOKEN_NOT_FOUND = createType(type, 'TOKEN_NOT_FOUND');;

export const RESET_FORM = createType(type, 'RESET_FORM');;