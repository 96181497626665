export const formControlsValue = {
  smsText: '',
  emailSubject: '',
  emailBody: ''
};

export const formControlsOptions = {
  smsText: {
    label: 'SMS text',
    id: 'smsText',
    name: 'smsText',
    placeholder: 'SMS text',
    rules: {
      isRequired: true,
    }
  },
  emailSubject: {
    label: 'Subject',
    id: 'emailSubject',
    name: 'emailSubject',
    placeholder: 'Subject',
    rules: {
      isRequired: true,
    }
  },
  emailBody: {
    label: 'Body',
    id: 'emailBody',
    name: 'emailBody',
    placeholder: 'Body',
    rules: {
      isRequired: true,
    }
  }
};
