import './App.scss';

import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import { checkIsTokenExpired } from './actions/auth.actions'
// Layouts
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Drawer from './layouts/Drawer';
import { Notifications } from './layouts/Notifications';
// Components
import { AppLoading } from './components/Loadings';
// Routing
import { AppRouting } from './App.routing';

class App extends Component {
  state = {
    appLoading: true
  };

  componentWillMount(){
    this.props.checkIsTokenExpired();
    setTimeout(() => {
      this.setState({
        appLoading: false
      });
    }, 2000);
  }

  render(){
    const { appLoading } = this.state;
    const { isAuthenticated } = this.props;

    if ( appLoading ) return <AppLoading />
    if ( !appLoading ) return (
      <>
        <Header isAuthenticated={isAuthenticated} />
        <div className="root-main">
          {isAuthenticated && <Drawer />}
          <div className="root-container">
            <AppRouting />
          </div>
        </div>
        <Footer />
        {/* Notifications */}
        <Notifications />
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
  checkIsTokenExpired: () => dispatch(checkIsTokenExpired())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
