import React from 'react';
import { Link } from 'react-router-dom';
// Store
import { connect } from 'react-redux';

const Header = ({ isAuthenticated, drawerOpen }) => {
  const drawerClasses = () => {
    if ( isAuthenticated ){
      return drawerOpen ? 'is-open' : 'is-closed';
    }
    return '';
  }
  return (
    <div className={`root-header ${ drawerClasses() }`}>
      <nav className="navbar navbar-dark bg-primary">
        <Link className="navbar-brand" to={`${isAuthenticated ? `/admin/users` : `/`}`}>AdminUI</Link>
      </nav>
    </div>
  )
}

const mapStateToProps = state => ({
  drawerOpen: state.layout.drawerOpen
});

const mapDispatchToProps = dispatch => ({
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
