import createType from './create-type';

const type = 'user';

export const FETCH_ME = createType(type, 'FETCH_ME');
export const FETCH_ME_SUCCESS = createType(type, 'FETCH_ME_SUCCESS');

export const FETCH_USERS_LIST = createType(type, 'FETCH_USERS_LIST');
export const FETCH_USERS_LIST_SUCCESS = createType(type, 'FETCH_USERS_LIST_SUCESS');

export const CHECK_USER = createType(type, 'CHECK_USER');
