import { takeLatest, call, put } from 'redux-saga/effects';
// Service
import { $fetchUsersList } from '../services/user.service';
// Types
import * as Types from '../types/user.types';
// Actions
import {
  fetchUsersListSuccess
} from '../actions/user.actions';

// Fetch users list
function* _fetchUsersList(action){
  const response = yield call($fetchUsersList, action.params);
  yield put(fetchUsersListSuccess(response.data));
}

export function* userSaga(){
  yield takeLatest(Types.FETCH_USERS_LIST, _fetchUsersList);
}
