import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

// Root
import rootSaga from './sagas';
import rootReducer from './reducers';

export const history = createBrowserHistory();

export default function configureStore(preloadedState){
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const sagaMiddleware = createSagaMiddleware();

  const enhancer = composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware
    ),
  );

  const store = createStore(
    rootReducer(history),
    preloadedState,
    enhancer
  );

  sagaMiddleware.run(rootSaga);

  return store
}
