import * as Types from '../types/template.types';

// Fetch templates list
export const fetchTemplatesList = () => ({
  type: Types.FETCH_TEMPLATES_LIST
});
export const fetchTemplatesListSuccess = templates => ({
  type: Types.FETCH_TEMPLATES_LIST_SUCCESS,
  templates
});

// Create template
export const createTemplate = template => ({
  type: Types.CREATE_TEMPLATE,
  template
});
export const createTemplateSuccess = template => ({
  type: Types.CREATE_TEMPLATE_SUCCESS,
  template
});
export const createTemplateFailure = () => ({
  type: Types.CREATE_TEMPLATE_FAILURE
});

// Update Template
export const updateTemplate = template => ({
  type: Types.UPDATE_TEMPLATE,
  template
});
export const updateTemplateSuccess = template => ({
  type: Types.UPDATE_TEMPLATE_SUCCESS,
  template
});
export const updateTemplateFailure = () => ({
  type: Types.UPDATE_TEMPLATE_FAILURE
});

// Delete template
export const deleteTemplate = templateId => ({
  type: Types.DELETE_TEMPLATE,
  templateId
});
export const deleteTemplateSuccess = templateId => ({
  type: Types.DELETE_TEMPLATE_SUCCESS,
  templateId
});
export const deleteTemplateFailure = () => ({
  type: Types.DELETE_TEMPLATE_FAILURE
});

// Fetch templates variables
export const fetchTemplatesVariables = () => ({
  type: Types.FETCH_TEMPLATES_VARIABLES
});
export const fetchTemplatesVariablesSuccess = variables => ({
  type: Types.FETCH_TEMPLATES_VARIABLES_SUCCESS,
  variables
});

// 
export const setSelectedTemplate = template => ({
  type: Types.SET_SELECTED_TEMPLATE,
  template
});
export const clearSelectedTemplate = () => ({
  type: Types.CLEAR_SELECTED_TEMPLATE
});

export const resetForm = () => ({
  type: Types.RESET_FORM
});