import axios from 'axios';

import { config } from '../../config';

import { isExpired, getToken, getTokenType } from './storage.service';

const instance = axios.create({
  baseURL: `${config.apiUrl}/api`,
  transformRequest: [
    (data, headers) => {
      if ( !isExpired() ) headers['Authorization'] = `${getTokenType()} ${getToken()}`;
      return JSON.stringify(data);
    }
  ],
  headers: {
    'Content-Type': 'application/json'
  }
});

export default instance;
