import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// Pages
import Users from './Users';
import Templates from './Templates';
import Schedules from './Schedules';

const Admin = ({match}) => {
  return (
    <div className="root-scroller">
      <div className="root-scroller-inner">
        <Switch>
          <Route path={`${match.path}/users`} component={Users} />
          <Route path={`${match.path}/templates`} component={Templates} />
          <Route path={`${match.path}/schedules`} component={Schedules} />

          <Redirect from={`${match.path}`} to={`${match.path}/users`} />
        </Switch>
      </div>
    </div>
  )
}

export default Admin;
