import React from 'react'

const DrawerTop = ({ isOpen, onToggle }) => {
  return (
    <div className="root-drawer-top">
      <button
        className="btn btn-outline-primary btn-sm"
        type="button"
        onClick={onToggle}
      >
        {
          isOpen
          ? <i className="fa fa-chevron-left" aria-hidden="true"></i>
          : <i className="fa fa-chevron-right" aria-hidden="true"></i>
        }
      </button>
    </div>
  )
}

export default DrawerTop;
