import * as Types from '../types/layout.types';

export const toggleDrawer = () => ({
  type: Types.TOGGLE_DRAWER
});

let INDEX = 0;

// Notification
export const addNotification = notification => ({
  type: Types.ADD_NOTIFICATION,
  notification: {
    ...notification,
    id: INDEX++
  }
});

export const removeNotification = notification => ({
  type: Types.REMOVE_NOTIFICATION,
  notification
});
