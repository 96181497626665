import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getToken } from '../services/storage.service';

const PrivateRoute = (props) => {
  const { component: Component, path, ...rest } = props;
  return (
    <Route path={path} {...rest} render={(props) => {
      return getToken()
        ? <Component {...props} />
        : <Redirect to='/' />
    }} />
  );
}

export default PrivateRoute;
