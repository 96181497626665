import React, { Component } from 'react'

export default class UpdatePassword extends Component {
  render() {
    return (
      <div>
        Update password
      </div>
    )
  }
}
