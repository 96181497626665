import React from 'react';

const ButtonLoading = ({type = 'button', classess = 'btn btn-primary btn-sm', loading = false, children}) => {
  return (
    <button
      className={classess}
      type={type}
      disabled={loading}
    >
      {loading && <i className="fa fa-spinner fa-spin | mr-2"></i>}
      {children}
    </button>
  )
}

export default ButtonLoading;