import { takeLatest, call, put } from 'redux-saga/effects';
// Service
import { $sendNotification } from '../services/notification.service';
// Types
import * as Types from '../types/notification.types';
// Actions
import {
  sendNotificationSuccess, sendNotificationFailure,
  resetForm
} from '../actions/notification.actions';

// Send one time notification
function* _sendNotification(action){
  try {
    yield call($sendNotification, action.data);
    yield put(sendNotificationSuccess());
    yield put(resetForm());
  } catch(err){
    console.error(err);
    yield put(sendNotificationFailure());
  }
}

export function* notificationSaga(){
  yield takeLatest(Types.SEND_NOTIFICATION, _sendNotification);
}
