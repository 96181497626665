import React from 'react'

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="root-footer">
      &copy; { currentYear } All rights reserved
    </div>
  )
}

export default Footer;
