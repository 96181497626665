import React from 'react';

import validate from '../../utils/control.validate';

const InputControl = ({
  submitted = false, classes = 'form-control form-control-sm ', value, options, match, onChange, onFocus = () => {}
}) => {
  let { id, label, name, type = 'text', placeholder } = options;

  let inputRequired = false;

  if ( options.rules && options.rules.isRequired ) inputRequired = true;

  let valid = {
    isValid: true,
    validateMessage: ''
  };
  if ( submitted && inputRequired ){
    let msg;
    if ( typeof match !== 'undefined' && type === 'password' ) msg = 'Passwords do not match';
    valid = validate(value, options.rules, match, msg);
  }

  classes += !valid.isValid ? ' is-invalid' : '';

  return (
    <div className="form-group">
      {
        label &&
        <label htmlFor={id}>
          {label}
          {inputRequired && <span>&nbsp;*</span>}
        </label>
      }
      <input
        className={classes}
        id={id}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        required={inputRequired}
      />
      {
        valid.validateMessage &&
        <div className="invalid-feedback">{valid.validateMessage}</div>
      }
    </div>
  )
}

export default InputControl;