import { store } from '../../index.js';
import { addNotification } from '../actions/layout.actions'

export const responseHandler = (response, message) => {
  if ( message ) store.dispatch(addNotification({
    type: 'success',
    message
  }));
  return response;
}

export const errorHandler = error => {
  if ( typeof error === 'string' ){
    store.dispatch(addNotification({
      type: 'danger',
      message: error
    }));
  }

  if ( error && error.response ){
    const { data } = error.response;
    store.dispatch(addNotification({
      type: 'danger',
      message: data.message
    }));
    throw(data);
  }
}
