import * as Types from '../types/template.types';

const initialState = {
  templates: [],
  templatesVariables: [],
  selectedTemplate: {},

  fetching: false,
  fetched: false,

  modalFetching: false,
  modalFetched: false,

  formLoading: false,
  formSuccess: false
};

export default function(state = initialState, action){
  const { type } = action;
  switch (type){
    // Request
    case Types.FETCH_TEMPLATES_LIST:
      return _fetchTemplatesList(state);
    case Types.FETCH_TEMPLATES_VARIABLES:
      return _fetchTemplatesVariables(state);
    case Types.CREATE_TEMPLATE:
    case Types.UPDATE_TEMPLATE:
      return { ...state, formLoading: true, formSuccess: false };
    // Success
    case Types.FETCH_TEMPLATES_LIST_SUCCESS:
      return _fetchTemplatesListSuccess(state, action.templates);
    case Types.CREATE_TEMPLATE_SUCCESS:
      return _createTemplateSuccess(state, action.template);
    case Types.UPDATE_TEMPLATE_SUCCESS:
      return _updateTemplateSuccess(state, action.template);
    case Types.DELETE_TEMPLATE_SUCCESS:
      return _deleteTemplateSuccess(state, action.templateId);
    case Types.FETCH_TEMPLATES_VARIABLES_SUCCESS:
      return _fetchTemplatesVariablesSuccess(state, action.variables);
    // Failure
    case Types.CREATE_TEMPLATE_FAILURE:
    case Types.UPDATE_TEMPLATE_FAILURE:
      return { ...state, formLoading: false, formSuccess: false };
    // 
    case Types.SET_SELECTED_TEMPLATE:
      return { ...state, selectedTemplate: action.template };
    case Types.CLEAR_SELECTED_TEMPLATE:
      return { ...state, selectedTemplate: {} };
    // Reset form
    case Types.RESET_FORM:
      return { ...state, formLoading: false, formSuccess: false };
    default:
      return state;
  }
}

// Fetch templates variables
const _fetchTemplatesList = state => ({
  ...state, templates: [], fetching: true, fetched: false
});
const _fetchTemplatesListSuccess = (state, templates) => ({
  ...state, templates, fetching: false, fetched: true
});

// Create template
const _createTemplateSuccess = (state, template) => ({
  ...state,
  templates: [...state.templates, template],
  formLoading: false,
  formSuccess: true
});
// Update template
const _updateTemplateSuccess = (state, template) => ({
  ...state,
  templates: state.templates.map(t => t.id === template.id ? {...t, ...template} : t),
  formLoading: false,
  formSuccess: true
});
// Delete template
const _deleteTemplateSuccess = (state, templateId) => ({
  ...state,
  templates: state.templates.filter(t => t.id !== templateId)
});

// Fetch templates variables
const _fetchTemplatesVariables = state => ({
  ...state, templatesVariables: {}, modalFetching: true, modalFetched: false
});
const _fetchTemplatesVariablesSuccess = (state, templatesVariables) => ({
  ...state, templatesVariables, modalFetching: false, modalFetched: true
});
