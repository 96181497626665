import React, { Component } from 'react';
import dayjs from 'dayjs';

import { InputControl } from '../../../components/FormControls';

class UsersFilter extends Component {
  state = {
    formControlsValue: {
      visitingFrom: '',
      visitingTo: ''
    },
    formControlsOptions: {
      visitingFrom: {
        id: 'visitingFrom',
        label: 'Visiting from',
        name: 'visitingFrom',
        type: 'date',
        placeholder: 'Visiting from'
      },
      visitingTo: {
        id: 'visitingTo',
        label: 'Visiting to',
        name: 'visitingTo',
        type: 'date',
        placeholder: 'Visiting to'
      }
    }
  }

  handleChange = evt => {
    const { target: { name, value } } = evt;
    this.setState(prevState => ({
      formControlsValue: {
        ...prevState.formControlsValue,
        [name]: value
      }
    }));
  }

  handleClear = evt => {
    this.setState({
      formControlsValue: {
        visitingFrom: '',
        visitingTo: ''
      }
    }, () => {
      this.props.onFetchUsersList();
    })
  }

  handleSubmit = evt => {
    evt.preventDefault();

    const { formControlsValue: { visitingFrom, visitingTo } } = this.state;

    if ( visitingFrom || visitingTo ){
      const params = {}
      if ( visitingFrom ) params['visitingFrom'] = dayjs(visitingFrom).hour(0).minute(0).second(0).toISOString();
      if ( visitingTo ) params['visitingTo'] = dayjs(visitingTo).hour(23).minute(59).second(59).toISOString();

      this.props.onFetchUsersList(params);
    }
  }

  render() {
    const { formControlsValue, formControlsOptions } = this.state;
    return (
      <form
        noValidate
        name="form"
        onSubmit={this.handleSubmit}
      >
        <div className="row row-8">
          <div className="col-12 col-sm-4">
            <InputControl
              value={formControlsValue.visitingFrom}
              options={formControlsOptions.visitingFrom}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-12 col-sm-4">
            <InputControl
              value={formControlsValue.visitingTo}
              options={formControlsOptions.visitingTo}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-12 col-sm-2">
            <div className="form-group">
              <label htmlFor="">&nbsp;</label>
              <button
                className="btn btn-outline-danger btn-sm btn-block"
                type="button"
                onClick={this.handleClear}
              >Clear</button>
            </div>
          </div>
          <div className="col-12 col-sm-2">
            <div className="form-group">
              <label htmlFor="">&nbsp;</label>
              <button
                className="btn btn-primary btn-sm btn-block"
                type="submit"
              >Apply</button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default UsersFilter;
