import './DataLoading.scss';

import React from 'react';

const DataLoading = () => {
  return (
    <div className="data-loading">Loading...</div>
  );
}

export default DataLoading;
