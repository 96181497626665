import './Notifications.scss';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// Store
import { connect } from 'react-redux';
import { removeNotification } from '../../actions/layout.actions';

class Notifications extends Component {
  handleRemove = notification => () => {
    this.props.removeNotification(notification)
  }

  render() {
    const { notifications } = this.props;
    if ( notifications.length ) return ReactDOM.createPortal(
      <div className="notifications">
        {notifications.map((item, index) => (
          <div
            key={index}
            className={`notifications-item is-${item.type}`}
          >
            <span>{item.message}</span>
            <button
              className="btn btn-link btn-sm"
              onClick={this.handleRemove(item)}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        ))}
      </div>,
      document.getElementById('root-notification')
    );
    return null;
  }
}

const mapStateToProps = state => ({
  notifications: state.layout.notifications
});

const mapDispatchToProps = dispatch => ({
  removeNotification: notification => dispatch(removeNotification(notification))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
