import { replace } from 'connected-react-router';
import { takeLatest, call, put } from 'redux-saga/effects';
// Service
import { getToken, setStorageCredential, clearStorageCredential } from '../services/storage.service';
import { $login, $logout, $checkIsTokenExpired } from '../services/auth.service';
import { $fetchMe } from '../services/user.service';
// Types
import * as AuthTypes from '../types/auth.types';
import * as UserTypes from '../types/user.types';
// Actions
import {
  setLoginCredential, loginFailure,
  logout, logoutSuccess,
  tokenNotFound
} from '../actions/auth.actions';
import {
  fetchMe, fetchMeSuccess
} from '../actions/user.actions';

// Login
function* _login(action){
  try {
    const response = yield call($login, action.credential);
    setStorageCredential(response.data);
    yield put(setLoginCredential(response.data));
    yield put(fetchMe());
  } catch(err){
    console.error(err);
    yield put(loginFailure());
  }
}

// Logout
function* _logout(){
  yield call($logout);
  clearStorageCredential();
  yield put(logoutSuccess());
  yield put(replace('/'));
}

// Fetch me
function* _fetchMe(){
  try {
    const response = yield call($fetchMe);
    yield put(fetchMeSuccess(response.data));
    const { role } = response.data;
    const { pathname } = window.location;
    if ( pathname.replace('/', '') === '' ){
      if ( role === 'admin' ) yield put(replace('/admin/users'));
    }
  } catch(err){
    console.error(err);
  }
}

// Check is token expired
function* _checkIsTokenExpired(){
  try {
    const response = yield call($checkIsTokenExpired);
    console.info('Token exist', response);
    if ( response ){
      yield put(setLoginCredential({
        accessToken: getToken() 
      }));
      yield put(fetchMe());
    } else {
      yield put(tokenNotFound());
    }
  } catch(err){
    yield put(logout());
  }
}

export function* authSaga(){
  yield takeLatest(AuthTypes.LOGIN, _login);
  yield takeLatest(AuthTypes.LOGOUT, _logout);
  yield takeLatest(UserTypes.FETCH_ME, _fetchMe);
  yield takeLatest(AuthTypes.CHECK_IS_TOKEN_EXPIRED, _checkIsTokenExpired)
}