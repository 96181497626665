import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
// Components
import { DataMessage } from '../../../components/DataMessage';

class Schedules extends Component {
  componentWillMount(){

  }

  render() {
    return (
      <div className="area area-templates">
        <div className="container-fluid">
          {/* <h1 className="area-title">Schedule</h1> */}
          <div className="area-container">
            <DataMessage data={[]}>
              Has schedules
            </DataMessage>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Schedules);
