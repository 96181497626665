import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import {
  createTemplate,
  updateTemplate,
  fetchTemplatesVariables
} from '../../../actions/template.actions';
// React bootstrap
import { Modal } from 'react-bootstrap';
// Components
import { DataLoading } from '../../../components/Loadings';
import { ButtonLoading } from '../../../components/Buttons';
// 
import { formControlsValue, formControlsOptions } from './FormControls';
import FormFields from './FormFields';

class TemplatesFormModal extends Component {
  state = {
    formSubmitted: false,
    formControlsValue,
    formControlsOptions
  };

  componentWillReceiveProps(newProps){
    const { formSuccess, onHide } = newProps;
    if ( formSuccess ) onHide();
  }

  componentWillMount(){
    const { selectedTemplate } = this.props;

    if ( selectedTemplate && selectedTemplate.id ){
      this.setState(prevState => {
        const valueState = {
          name: selectedTemplate.name,
          deliverySchedule: selectedTemplate.deliverySchedule,
          deliveryHour: selectedTemplate.deliveryHour,
          smsText: selectedTemplate.sms.text,
          emailSubject: selectedTemplate.email.subject,
          emailBody: selectedTemplate.email.body
        };
        if ( typeof selectedTemplate.deliveryDaysSpan !== 'undefined' ){
          valueState['deliveryType'] =
            selectedTemplate.deliveryDaysSpan !== 0
              ? selectedTemplate.deliveryDaysSpan > 0
                ? 'after'
                : 'before'
              : 0
          ;
          valueState['deliveryDaysSpan'] =
            selectedTemplate.deliveryDaysSpan < 0
            ? selectedTemplate.deliveryDaysSpan * -1
            : selectedTemplate.deliveryDaysSpan
          ;
        }
        return {
          formControlsValue: {
            ...prevState.formControlsValue,
            ...valueState
          }
        }
      }, () => {
        this.props.fetchTemplatesVariables();
      });
    } else {
      this.props.fetchTemplatesVariables();
    }
  }

  handleChange = evt => {
    const { target: { name, value } } = evt;
    this.setState(prevState => ({
      formControlsValue: {
        ...prevState.formControlsValue,
        [name]: value
      }
    }));
  }

  handleSubmit = evt => {
    evt.preventDefault();

    this.setState({
      formSubmitted: true
    });

    if ( !this.refForm.checkValidity() ) return;

    const {
      formControlsValue: {
        name, deliverySchedule, deliveryType, deliveryDaysSpan, deliveryHour, smsText, emailSubject, emailBody
    } } = this.state;
    const { selectedTemplate } = this.props;

    const template = {
      name,
      deliverySchedule,
      deliveryHour: parseInt(deliveryHour, 10),
      sms: {
        text: smsText
      },
      email: {
        subject: emailSubject,
        body: emailBody,
      }
    };

    if (
      deliverySchedule === 'arrival' ||
      deliverySchedule === 'departure'
    ){
      template['deliveryDaysSpan'] =
        deliveryType !== 'sameDay'
        ? 
          deliveryType === 'before'
          ? -(parseInt(deliveryDaysSpan, 10))
          : parseInt(deliveryDaysSpan, 10)
        : 0
      ;
    }

    if ( selectedTemplate && selectedTemplate.id ){
      this.props.updateTemplate({...selectedTemplate, ...template});
    } else {
      this.props.createTemplate(template);
    }
  }

  render() {
    const { formSubmitted, formControlsValue, formControlsOptions } = this.state;
    const {
      show, onHide,
      selectedTemplate, templatesVariables, modalFetching, modalFetched, formLoading
    } = this.props;
    return (
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
      >
        <form
          noValidate
          name="form"
          ref={ref => this.refForm = ref}
          onSubmit={this.handleSubmit}
        >
          <Modal.Header closeButton>
            {selectedTemplate && selectedTemplate.id
              ? <Modal.Title>Update template</Modal.Title>
              : <Modal.Title>Create template</Modal.Title>
            }
          </Modal.Header>
          <Modal.Body>
            {(modalFetching && !modalFetched) && <DataLoading />}
            {(!modalFetching && modalFetched) && 
              <FormFields
                templatesVariables={templatesVariables}

                formSubmitted={formSubmitted}
                formControlsValue={formControlsValue}
                formControlsOptions={formControlsOptions}
                onChange={this.handleChange}
              />
            }
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={onHide}
            >Cancel</button>
            <ButtonLoading
              type="submit"
              loading={formLoading}
            >Save</ButtonLoading>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

TemplatesFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  templatesVariables: state.template.templatesVariables,
  selectedTemplate: state.template.selectedTemplate,

  modalFetching: state.template.modalFetching,
  modalFetched: state.template.modalFetched,

  formLoading: state.template.formLoading,
  formSuccess: state.template.formSuccess,
});

const mapDispatchToProps = dispatch => ({
  createTemplate: data => dispatch(createTemplate(data)),
  updateTemplate: data => dispatch(updateTemplate(data)),
  fetchTemplatesVariables: () => dispatch(fetchTemplatesVariables())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplatesFormModal);
