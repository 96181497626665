export const formControlsValue = {
  email: '',
  password: ''
};

export const formControlsOptions = {
  email: {
    id: 'email',
    label: 'E-mail',
    name: 'email',
    type: 'email',
    placeholder: 'E-mail',
    rules: {
      isRequired: true,
      isEmail: true
    }
  },
  password: {
    id: 'password',
    label: 'Password',
    name: 'password',
    type: 'password',
    placeholder: 'Password',
    rules: {
      isRequired: true
    }
  }
}
