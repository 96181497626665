import axios from './axios.instance';
// Utils
import { responseHandler, errorHandler } from '../utils/request.handler';

const _CREATE_TEMPLATE = 'Template was created';
const _UPDATE_TEMPLATE = 'Template was updated';
const _DELETE_TEMPLATE = 'Template was deleted';

// Fetch templates list
export function $fetchTemplatesList(){
  return axios.get('/admin/notificationTemplates');
}

export function $createTemplate(template){
  return axios.post('/admin/notificationTemplates', template)
    .then(response => responseHandler(response, _CREATE_TEMPLATE))
    .catch(errorHandler);
}

export function $updateTemplate(template){
  return axios.put(`/admin/notificationTemplates/${template.id}`, template)
    .then(response => responseHandler(response, _UPDATE_TEMPLATE))
    .catch(errorHandler);
}

export function $deleteTemplate(templateId){
  return axios.delete(`/admin/notificationTemplates/${templateId}`)
    .then(response => responseHandler(response, _DELETE_TEMPLATE))
    .catch(errorHandler);
}

export function $fetchTemplatesVariables(){
  return axios.get('/admin/notificationTemplates/variables');
}
