import * as Types from '../types/layout.types';

const initialState = {
  drawerOpen: true,
  notifications: []
};

export default function(state = initialState, action){
  const { type } = action;
  switch (type){
    case Types.TOGGLE_DRAWER:
      return {...state, drawerOpen: !state.drawerOpen};
    case Types.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.notification]
      };
    case Types.REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(n => n.id !== action.notification.id )
      };
    default:
      return state;
  }
}
