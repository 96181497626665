const TOKEN = 'aicamping:token';
const TOKEN_TYPE = 'aicamping:token_type';
const EXPIRES_IN = 'aicamping:expires_in';

export const getToken = () => localStorage.getItem(TOKEN);
export const getTokenType = () => localStorage.getItem(TOKEN_TYPE);
export const getExpiresIn = () => parseInt(localStorage.getItem(EXPIRES_IN), 10);

export const isExpired = () => {
  const currentTime = new Date().getTime();
  const expiresInTime = getExpiresIn();
  return (getToken() && (currentTime > expiresInTime));
}

export const setStorageCredential = ({accessToken, expiresIn, tokenType}) => {
  const date = new Date();
  localStorage.setItem(TOKEN, accessToken);
  localStorage.setItem(TOKEN_TYPE, tokenType);
  localStorage.setItem(EXPIRES_IN, date.setSeconds(date.getSeconds() + expiresIn));
}

export const clearStorageCredential = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(TOKEN_TYPE);
  localStorage.removeItem(EXPIRES_IN);
}
