import axios from './axios.instance';

// Fetch me
export function $fetchMe(){
  return axios.get('/admin/users/me');
}

// Fetch users list
export function $fetchUsersList(params){
  const searchParams = new URLSearchParams();
  if ( params && params.visitingFrom ) searchParams.append('visitingFrom', params.visitingFrom);
  if ( params && params.visitingTo ) searchParams.append('visitingTo', params.visitingTo);
  return axios.get(`/admin/users${searchParams ? `?${searchParams.toString()}` : '' }`);
}