import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import { checkUser } from '../../../actions/user.actions';
// Compoennt
import { DataMessage } from '../../../components/DataMessage';

class UsersList extends Component {
  state = {
    checkedUsers: new Map()
  }

  componentWillMount(){
    const { checkedUsers } = this.props;
    if ( checkedUsers.length > 0 ){
      const n = new Map();
      for ( let i in checkedUsers ){
        let user = checkedUsers[i];
        n.set(`userCheckbox${user.id}`, true)
      }
      this.setState({
        checkedUsers: n
      });
    }
  }

  handleChange = user => evt => {
    const { name, checked } = evt.target;
    this.setState(prevState => ({
      checkedUsers: prevState.checkedUsers.set(name, checked)
    }), () => {
      this.props.checkUser(user);
    });
  }

  render(){
    const { users } = this.props;
    return (
      <DataMessage data={users} type="user">
        <ul className="list-group">
          {users.map((user, index) => (
            <li
              key={index}
              className="list-group-item d-flex justify-content-start align-items-center"
            >
              <div>
                <input
                  type="checkbox"
                  name={`userCheckbox${user.id}`}
                  onChange={this.handleChange(user)}
                  checked={!!this.state.checkedUsers.get(`userCheckbox${user.id}`)}
                />
              </div>
              <div className="flex-fill pl-2">
                <h5 className="mb-0">{user.name}</h5>
                <p className="mb-0 text-muted">{user.email}</p>
              </div>
            </li>
          ))}
        </ul>
      </DataMessage>
    )
  }
}

const mapStateToProps = state => ({
  checkedUsers: state.user.checkedUsers,
  users: state.user.users,
});

const mapDispatchToProps = dispatch => ({
  checkUser: user => dispatch(checkUser(user))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList);
