import React from 'react';

import validate from '../../utils/control.validate';

const SelectControl = ({
  value, options, classes = 'form-control form-control-sm ', submitted = false, onChange
}) => {
  let { id, label, name, choises } = options;
  let inputRequired = false;

  if ( options.rules && options.rules.isRequired ) inputRequired = true;

  let valid = {
    isValid: true,
    validateMessage: ''
  };
  if ( submitted ) valid = validate(value, options.rules);

  classes += !valid.isValid ? ' is-invalid' : '';

  return (
    <div className="form-group">
      {
        label &&
        <label htmlFor={id}>
          {label}
          {inputRequired && <span>&nbsp;*</span>}
        </label>
      }
      <select
        className={classes}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        required={inputRequired}
      >
        {
          choises.map((item, index) => (
            <option
              key={index}
              value={item.value}
            >{item.label}</option>
          ))
        }
      </select>
      {
        valid.validateMessage &&
        <div className="invalid-feedback">{valid.validateMessage}</div>
      }
    </div>
  )
}

export default SelectControl;