import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import { fetchTemplatesVariables } from '../../../actions/template.actions';
import { sendNotification } from '../../../actions/notification.actions';
// React bootstrap
import { Modal } from 'react-bootstrap';
// Components
import { DataLoading } from '../../../components/Loadings';
import { InputControl, TextareaControl } from '../../../components/FormControls';
import { ButtonLoading } from '../../../components/Buttons';

// 
import { formControlsValue, formControlsOptions } from './FormControls';

class UsersNotificationModal extends Component {
  state = {
    selectedVariable: '',
    formSubmitted: false,
    formControlsValue,
    formControlsOptions
  }

  componentWillMount(){
    this.props.fetchTemplatesVariables();
  }

  componentWillReceiveProps(newProps){
    const { formSuccess, onHide } = newProps;
    if ( formSuccess ) onHide();
  }

  handleChange = evt => {
    const { target: { name, value } } = evt;
    this.setState(prevState => ({
      formControlsValue: {
        ...prevState.formControlsValue,
        [name]: value
      }
    }))
  }

  handleFocus = evt => {
    const { selectedVariable } = this.state;

    if ( selectedVariable ){
      const { target: { name, value } } = evt;
      this.handleChange({ target: { name, value: value + selectedVariable } });
      this.handleClearVariable();
    }
  }

  handleSelectVariable = selectedVariable => () => {
    this.setState({
      selectedVariable
    });
  }

  handleClearVariable = () => {
    this.setState({
      selectedVariable: ''
    });
  }

  handleSubmit = evt => {
    evt.preventDefault();

    this.setState({
      formSubmitted: true
    });

    if ( !this.refForm.checkValidity() ) return;

    const { formControlsValue: { smsText, emailSubject, emailBody } } = this.state;

    const data = {
      sms: {
        text: smsText
      },
      email: {
        subject: emailSubject,
        body: emailBody
      },
      userIds: this.props.checkedUsers.map(u => u.id)
    };

    this.props.sendNotification(data);
  }

  render() {
    const { selectedVariable, formSubmitted, formControlsValue, formControlsOptions } = this.state;
    const {
      show,
      templatesVariables, modalFetching, modalFetched, formLoading
    } = this.props;
    return (
      <Modal
        size="lg"
        show={show}
        onHide={this.props.onHide}
      >
        <form
          noValidate
          name="form"
          ref={ref => this.refForm = ref}
          onSubmit={this.handleSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title>One time notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {(modalFetching && !modalFetched) && <DataLoading />}
            {(!modalFetching && modalFetched) && 
              <div className="row row-8">
                <div className="col-12 col-sm-8">
                  <fieldset>
                    <legend>SMS</legend>
                    <TextareaControl
                      value={formControlsValue.smsText}
                      options={formControlsOptions.smsText}
                      submitted={formSubmitted}
                      onChange={this.handleChange}
                      onFocus={this.handleFocus}
                    />
                  </fieldset>
                  <fieldset>
                    <legend>E-mail</legend>
                    {/* E-mail subject */}
                    <InputControl
                      value={formControlsValue.emailSubject}
                      options={formControlsOptions.emailSubject}
                      submitted={formSubmitted}
                      onChange={this.handleChange}
                      onFocus={this.handleFocus}
                    />
                    {/* E-mail body */}
                    <TextareaControl
                      value={formControlsValue.emailBody}
                      options={formControlsOptions.emailBody}
                      submitted={formSubmitted}
                      onChange={this.handleChange}
                      onFocus={this.handleFocus}
                    />
                  </fieldset>
                </div>
                <div className="col-12 col-sm-4">
                  {templatesVariables.map((item, index) => {
                    if ( item.var === '${user.name}' ){
                      return (
                        <div
                          key={index}
                          className="form-group"
                        >
                          <button
                            className="btn btn-primary btn-sm btn-block"
                            type="button"
                            onClick={this.handleSelectVariable(item.var)}
                          >{item.var}</button>
                          <small className="form-text text-muted">{item.description}</small>
                        </div>
                      )
                    }
                  })}
                  {selectedVariable &&
                    <button
                      className="btn btn-light btn-sm btn-block"
                      type="button"
                      onClick={this.handleClearVariable}
                    >Clear</button>
                  }
                </div>
              </div>
            }

          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-light btn-sm"
              type="button"
              onClick={this.props.onHide}
            >Cancel</button>
            <ButtonLoading
              type="submit"
              loading={formLoading}
            >Send</ButtonLoading>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

UsersNotificationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

const mapStateToProps = state => ({
  checkedUsers: state.user.checkedUsers,

  templatesVariables: state.template.templatesVariables,
  modalFetching: state.template.modalFetching,
  modalFetched: state.template.modalFetched,

  formLoading: state.notification.formLoading,
  formSuccess: state.notification.formSuccess
});

const mapDispatchToProps = dispatch => ({
  fetchTemplatesVariables: () => dispatch(fetchTemplatesVariables()),
  sendNotification: data => dispatch(sendNotification(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersNotificationModal);
